import React from 'react';
import { View, Text, ImageBackground, Image, ScrollView,Dimensions } from 'react-native';
import style from './style';
import api from '../config/api_urls';
import Menu from '../menu/menu';
import ReactMarkdown from 'react-markdown';
import AsyncStorage from '@react-native-community/async-storage';


export default class Textos extends React.Component {
    state = {};
    constructor(props) {
        super(props);
        this.state = props.route.params;
        // console.log(this.state.estatSelectedItinerari)
        let escrits = [];
        try {

            for(let poi of this.state.estatSelectedItinerari.pois) {
                let nom_poi = this.state.selectedItinerari.pois.find(e => e.id === poi.id)['Nom' + this.state.idioma];
                // console.log(poi.respostesJocs?.Observacio)
                // console.log(poi.respostesJocs?.Observacio.length)
                if(poi.respostesJocs?.Observacio && poi.respostesJocs.Observacio.length !== 0) {
                    escrits.push({
                        tipus: "observacio",
                        nom_poi: nom_poi,
                        resposta: poi.respostesJocs.Observacio
                    })
                }
                if(poi.respostesJocs?.Escrit?.resposta) {
                    escrits.push({
                        tipus: "escrit",
                        nom_poi: nom_poi,
                        resposta: poi.respostesJocs.Escrit.resposta
                    })
                }
            }
            this.state.escrits = escrits

        } catch (error) {
            console.log(error);
        }
        
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <View style={{ flex: 15 }}>
                    <ImageBackground source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '05_background_tipus4').imatge_recurs.url}} style={style.imageBackground}>
                        <ScrollView style={{height: Dimensions.get('screen').height * (13 / 15) * 0.50}}>
                            <View style={{ marginHorizontal: '5%', color: this.state.projecte.ColorCorporatiuText2, fontSize: 13, textAlign: "center", fontFamily: 'OpenSans' }}>
                                {this.state.escrits ? ( this.state.escrits.map((escrit, index) => {
                                    if (escrit.tipus === "observacio") {
                                        return (
                                            <View key={index}>
                                                <View style={{height: 80, alignItems: 'left', justifyContent: 'left'}}>
                                                    <ImageBackground source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'observacio_panell_icona')['imatge_recurs'].url}}
                                                                     resizeMode="contain" style={style.questionBackground}>
                                                          <View style={{justifyContent: 'center', alignItems: 'center',alignSelf:'center',paddingTop: Dimensions.get('screen').height * 2/100}}>
                                                            <Text style={style.titolReq}>{escrit.nom_poi}</Text>
                                                        </View>
                                                    </ImageBackground>
                                                </View>
                                                <Text style={{
                                                    fontSize: 18,
                                                    fontWeight: "bold",
                                                    fontFamily: 'OpenSans'
                                                }}></Text>
                                                <Text style={style.descripcio}>{this.state.recursos.find(e => e.tipus_recurs === 'observacio_5coses')['text_recurs' + this.state.idioma]}</Text>
                                                <Text style={style.text}>{escrit.resposta?.resposta1}</Text>
                                                <Text style={style.descripcio}>{this.state.recursos.find(e => e.tipus_recurs === 'observacio_4coses')['text_recurs' + this.state.idioma]}</Text>
                                                <Text style={style.text}>{escrit.resposta?.resposta2}</Text>
                                                <Text style={style.descripcio}>{this.state.recursos.find(e => e.tipus_recurs === 'observacio_3coses')['text_recurs' + this.state.idioma]}</Text>
                                                <Text style={style.text}>{escrit.resposta?.resposta3}</Text>
                                                <Text style={style.descripcio}>{this.state.recursos.find(e => e.tipus_recurs === 'observacio_2coses')['text_recurs' + this.state.idioma]}</Text>
                                                <Text style={style.text}>{escrit.resposta?.resposta4}</Text>
                                                <Text style={style.descripcio}>{this.state.recursos.find(e => e.tipus_recurs === 'observacio_1coses')['text_recurs' + this.state.idioma]}</Text>
                                                <Text style={style.text}>{escrit.resposta?.resposta5}</Text>
                                            </View>
                                        )
                                    }
                                    if (escrit.tipus === "escrit") {
                                        return (
                                            <View key={index}>
                                                <Text style={{
                                                    fontSize: 18,
                                                    fontWeight: "bold",
                                                    fontFamily: 'OpenSans'
                                                }}></Text>
                                                <View style={{height: 80, alignItems: 'left', justifyContent: 'left'}}>
                                                    <View style={{height: 80, alignItems: 'left', justifyContent: 'left'}}>
                                                    <ImageBackground source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'escrit_panell')['imatge_recurs'].url}}
                                                                     resizeMode="contain" style={style.questionBackground}>
                                                          <View style={{justifyContent: 'center', alignItems: 'center',alignSelf:'center',paddingTop: Dimensions.get('screen').height * 2/100}}>
                                                            <Text style={style.titolReq}>{escrit.nom_poi}</Text>
                                                        </View>
                                                    </ImageBackground>
                                                </View>
                                                </View>
                                                <Text style={style.text}>{escrit.resposta}</Text>
                                            </View>
                                        )
                                    }
                                })) : (<View/>)}
                            </View>
                        </ScrollView>
                        <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.10}} />
                    </ImageBackground>
                </View>
                <View style={{ flex: 2, bottom:0,left:0,right:0, position:'fixed', }}>
                    <Menu projecte={this.state}></Menu>
                </View>
            </View>
        )
    }
}