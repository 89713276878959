import React from 'react';
import {
    SafeAreaView,
    FlatList,
    View,
    Text,
    ImageBackground,
    TouchableOpacity,
    Image,
    Dimensions,
    ScrollView
} from 'react-native';
import style from './style';
import api from '../../config/api_urls'
import {gameId} from '../../Mapa/SampleData';
import Menu from "../../menu/menu";
import BounceLoader from "react-spinners/BounceLoader";
import ReactMarkdown from 'react-markdown'

export default class Meditacio extends React.Component {

    constructor(props) {
        super(props);
        this.state = props.route.params;
        this.state["loading"] = false;
        this.state["showContinue"] = false;
        this.state['interval'] ;
        
        this.state['loaded'] = false;
    }

    _audioFinished() {
        this.setState({loading: true})
        setTimeout(function() { //Start the timer
            this.setState({ checkIn: true });
            this.setState({loading: false});
            clearInterval(this.state.interval)
        }.bind(this), 1000)
    }

    checkAudioFinish()
    {
    this.state.interval=setInterval(()=>{
        if(document.getElementById('audio'))
        {
            document.getElementById('audio').onended = () => {
                this._audioFinished()
            };
        }
        },500)
    }


    goPremi() {
        this.props.navigation.navigate('Premi', this.state);
    }


    _paintButton() {
        if(!this.state.jocActiu["Audio"+this.state.idioma]?.url)
        {
            this.state.checkIn = true;
        }
        if(this.state.loading) {
            return(
                <BounceLoader size={50} color={"#715f4d"} loading={this.state.loading} speedMultiplier={1.5} />
            )
        }
        else if(!this.state.loading && !this.state.checkIn ){
            return(
                <TouchableOpacity style={style.boxContinuar} onPress={() => {
                    document.getElementById('audio').play(); this.checkAudioFinish() 
                }}>
                    <Text style={style.continue}>Audio</Text>
                </TouchableOpacity>
            )
        }
        else if(!this.state.loading && this.state.checkIn){
            return(
                <TouchableOpacity style={style.boxContinuar} onPress={() => {
                    this.goPremi()
                }}>
                    <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs === 'text_continuar')['text_recurs' + this.state.idioma]}</Text>
                </TouchableOpacity>
            )
        }

    }
    

    render(){
        return(
            <View style={style.container} >
                <View style={{ flex: 15}}>
                    <ImageBackground source={{ uri: api.api_url + this.state.itinerariActual['fons_joc'].url}} style={style.imageBackground}>
                        <View style={{flex: 9, justifyContent: 'center', alignItems: 'center', width: "100%"}}>
                            <View style={style.questionBackground}>
                            </View>
                        </View>
                        <View style={{flex: 64, justifyContent: 'center', alignItems: 'center', width: "100%"}}>
                            <View style={{flex: 40, width:"100%", alignItems: 'center', justifyContent: 'center'}}>
                                <Image
                                    resizeMode="contain"
                                    style={style.checkinImage}
                                    source={{uri:  api.api_url + this.state.jocActiu["Imatge"].url}} //this.state.idioma if you need it !!
                                />
                            </View>
                            <View style={{flex: 30, width: "80%",height:'100%', fontFamily: 'OpenSans', fontSize: 16,textAlign: "center",fontWeight: 500,paddingTop:20}}>
                                <ScrollView style={{height:'100%', fontFamily: 'OpenSans', fontSize: 16,textAlign: "center",fontWeight: 500,paddingTop:20,}}>
                                    <View style={{width: "100%", justifyContent: 'center', fontFamily: 'OpenSans', fontSize: "2.2vh"}}>
                                        <ReactMarkdown style={style.descripcio}>{this.state.jocActiu["descripcioDetallada" + this.state.idioma]}</ReactMarkdown>
                                    </View>
                                </ScrollView>
                            </View>
                        </View>
                        <View style={{flex: 15, justifyContent: 'center'}}>
                            {this._paintButton()}
                        </View>
                    <View style={{flex: 10}} />

                    </ImageBackground>
                    <audio id="audio" src={api.api_url + this.state.jocActiu["Audio"+this.state.idioma]?.url} preload></audio>
                </View>
                <View style={{ flex: 2, bottom: 0, left: 0, right: 0, position: 'fixed', }}>
                    
                        <Menu projecte={this.state} ></Menu>
                </View>
            </View>
        )
        
    } 
}
