import React from 'react';
import {View, Text, ImageBackground, Image, TouchableOpacity, ScrollView, Dimensions} from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import style from './style';
import api from '../config/api_urls';
import Menu from '../menu/menu';
import {Ionicons} from '@expo/vector-icons';
import AsyncStorage from '@react-native-community/async-storage';
import Modal from "modal-react-native-web";
import {postSatisfaccio} from "../../helpers/petitions";

//ordre 4
class MenuJocs extends React.Component {
    constructor(props) {
        super(props);
        this.state = props.route.params;
        this.state['jocActiu'] = {};
    }

    //this.state.poi
    loadPagina(tipus) {
        if (tipus == "Meditacio") {
            this.state.jocActiu = this.state.poi.jocs.find(joc => joc.Tipus == "Meditacio")
            this.props.navigation.navigate('Meditacio', this.state)
        }
        else if(tipus == "Moviment")
        {
            this.state.jocActiu = this.state.poi.jocs.find(joc => joc.Tipus == "Moviment")
            this.props.navigation.navigate('Moviment',this.state)
        }
        else if(tipus == "Escrit")
        {
            this.state.jocActiu = this.state.poi.jocs.find(joc => joc.Tipus == "Escrit")
            this.props.navigation.navigate('Relat',this.state)
        }
        else if(tipus == "Observacio")
        {
            this.state.jocActiu = this.state.poi.jocs.find(joc => joc.Tipus == "Observacio")
            this.props.navigation.navigate('Observacio',this.state)
        }

    }


    isJocDone(joc)
    {
        return( this.state.currentStateItinerari.pois.find(e => e.id === this.state.poi.id).jocs[joc])
    }

    render() {
        return (
            <View style={{flex: 1}}>
            <View style={{flex: 15}}>
            <ImageBackground
                    source={{uri: api.api_url + this.state.itinerariActual['Fons_exercici' + this.state.idioma].url}}
                    style={style.imageBackground}>
                    <View style={{flex: 1, width: "100%",justifyContent:'center',height:'80%'}}>
                        <View style={{flex: 1}}/>

                        <View style={{flex: 2}}>
                                <TouchableOpacity style={{flex: 1}} onPressIn={()=>{ this.loadPagina("Moviment")}} disabled={this.isJocDone("Moviment")} >
                                { this.isJocDone("Moviment") == false ?
                                 (
                                    <Image style={style.jocs} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == 'boto_moviment')['imatge_recurs' + this.state.idioma].url}} />
                                 )
                                 :
                                 (
                                    <Image style={style.jocs} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == 'boto_moviment_done')['imatge_recurs' + this.state.idioma].url}} />   
                                 ) 
                                }
                            </TouchableOpacity>
                        </View>
                        <View style={{flex: 2}}>
                            <TouchableOpacity style={{flex: 1}} onPressIn={()=>{ this.loadPagina("Meditacio") }} disabled={this.isJocDone("Meditacio")} >
                                { this.isJocDone("Meditacio") == false ?
                                (
                                    <Image style={style.jocs}  source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == 'boto_meditacio')['imatge_recurs' + this.state.idioma].url}} />
                                )
                                :
                                (
                                    <Image style={style.jocs} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == 'boto_meditacio_done')['imatge_recurs' + this.state.idioma].url}} />
                                ) 
                                }
                            </TouchableOpacity>
                        </View>
                        <View style={{flex: 2}}>
                            <TouchableOpacity style={{flex: 1}} onPressIn={()=>{ this.loadPagina("Observacio"); }} disabled={this.isJocDone("Observacio")}>
                                { this.isJocDone("Observacio") == false ?
                                    (
                                        <Image style={style.jocs}  source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == 'boto_observacio')['imatge_recurs' + this.state.idioma].url}} />
                                    )
                                    :
                                    (
                                        <Image style={style.jocs} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == 'boto_observacio_done')['imatge_recurs' + this.state.idioma].url}} />
                                    ) 
                                }                            </TouchableOpacity>
                        </View>
                        <View style={{flex: 2,paddingBottom:10}}>
                            <TouchableOpacity style={{flex: 1}} onPressIn={()=>{ this.loadPagina("Escrit"); }} disabled={this.isJocDone("Escrit")}>
                                { this.isJocDone("Escrit") == false ?
                                    (
                                        <Image style={style.jocs}  source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == 'boto_escrit')['imatge_recurs' + this.state.idioma].url}} />
                                    )
                                    :
                                    (
                                        <Image style={style.jocs} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == 'boto_escrit_done')['imatge_recurs' + this.state.idioma].url}} />
                                    ) 
                                }                               
                            </TouchableOpacity>
                        </View>
                    </View>
                </ImageBackground>
            </View>
            <View style={{flex:1}}>
                <View style={{ flex: 2, bottom: 0, left: 0, right: 0, position: 'fixed', }}>
                    <Menu projecte={this.state}></Menu>
                </View>
            </View>
        </View>
        )
    }
}

export default MenuJocs;

