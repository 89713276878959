import React from 'react';
import {View, Text, ImageBackground, Image, TouchableOpacity, ScrollView, Dimensions} from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import style from './style';
import api from '../config/api_urls';
import Menu from '../menu/menu';
import {Ionicons} from '@expo/vector-icons';
import AsyncStorage from '@react-native-community/async-storage';
import Modal from "modal-react-native-web";
import {postSatisfaccio} from "../../helpers/petitions";

//ordre 4
class ItinerarisHardCoded extends React.Component {
    constructor(props) {
        super(props);
        this.state = props.route.params;
        this.state = props.route.params;
        this.state['navigation'] = this.props.navigation;
        this.itineraris = (typeof this.state.projecte !== 'undefined' && typeof this.state.projecte.itineraris !== 'undefined') ? this.state.projecte.itineraris : [];
        this.state["selectedPage"] = {}
    }

    loadItinerari(itinerari) {
        this.state.itinerariActual = this.itineraris[itinerari];


        let retrievedObject = localStorage.getItem('itinerari' + this.state.itinerariActual.id);
        if (retrievedObject) {
            this.state['currentStateItinerari'] = JSON.parse(retrievedObject);
            this.props.navigation.navigate('Mapa', this.state)
        }
        else {
            this.props.navigation.navigate('InfoItinerari', this.state)
        }
    }

    async componentDidMount() {
        let finItinerari = await AsyncStorage.getItem('finalitzacio_itinerari')
        if(finItinerari) {
            this.setState({"finalitzacioItinerari": finItinerari})
        }
    }

    

    render() {
        return (
            <View style={{flex: 1}}>
            <View style={{flex: 15}}>
            <ImageBackground
                    source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == '04_background_menu_itinerari')['imatge_recurs' + this.state.idioma].url}}
                    style={style.imageBackground}>
                    <View style={{flex: 1, width: "100%",justifyContent:'center',height:'80%'}}>
                        <View style={{flex: 1}}/>

                            <View style={{flex: 2}}>
                            <TouchableOpacity style={{flex: 1}} onPressIn={()=>{  this.loadItinerari(0);}} >
                                <Image style={style.jocs} source={{uri: api.api_url + this.itineraris[0].Imatge.url}} />
                            </TouchableOpacity>
                            </View> 

                            <View style={{flex: 2}}>
                            <TouchableOpacity style={{flex: 1}} onPressIn={()=>{  this.loadItinerari(1);}} >
                                <Image style={style.jocs} source={{uri: api.api_url + this.itineraris[1].Imatge.url}} />
                                </TouchableOpacity>
                            </View> 

                            <View style={{flex: 2}}>
                            <TouchableOpacity style={{flex: 1}} onPressIn={()=>{  this.loadItinerari(2);}} >
                                <Image style={style.jocs} source={{uri: api.api_url + this.itineraris[2].Imatge.url}} />
                                </TouchableOpacity>
                            </View> 

                            <View style={{flex: 2}}>
                            <TouchableOpacity style={{flex: 1}} onPressIn={()=>{  this.loadItinerari(3);}} >
                                <Image style={style.jocs} source={{uri: api.api_url + this.itineraris[3].Imatge.url}} />
                                </TouchableOpacity>
                            </View> 

                    </View>
                </ImageBackground>
            </View>
            
            <View style={{flex:1}}>
                <View style={{ flex: 2, bottom: 0, left: 0, right: 0, position: 'fixed', }}>
                    <Menu projecte={this.state}></Menu>
                </View>
            </View>
        </View>
        )
    }
}

export default ItinerarisHardCoded;

