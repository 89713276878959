import AsyncStorage from '@react-native-community/async-storage';
import React, {Component} from 'react';
import {StyleSheet, Text, View, Image, Alert, TouchableOpacity, Platform, Dimensions} from 'react-native';
import api from '../config/api_urls';
import style from './style';


export default class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.props.projecte;
        //     console.log(this.state)
    }

    /* //=> Les sagrades linies den Giuseppe (NO BORRAR PORTEN SORT)

        _handlePressButtonAsync = async () => {
            let result = await WebBrowser.openBrowserAsync('https://studio.playvisit.com/app/1547');
            console.log(result);
          };
    */

    navigate = async (ruta) => {
        try {
            const value = await AsyncStorage.getItem('lastPoiTrucada');
            //   console.log(value);
            if (value !== null) {
                this.setState({premiTrucada: JSON.parse(value)});
                AsyncStorage.removeItem('lastPoiTrucada');
                this.state.navigation.navigate('Telefon', this.state);
            } else {
                this.props.projecte.navigation.navigate(ruta, this.state);
            }
        } catch (e) {
            console.log(e);
        }
    }

    renderBoto(navigate, icona_url) {
        if(icona_url)
            return (
                <TouchableOpacity style={[style.menu6]} onPress={async() =>  {
                    await this.navigate(navigate);
                }}>
                    <Image style={{width: 60, height: 60, alignSelf: 'center'}}
                           source={{uri: api.api_url + icona_url}}/>
                </TouchableOpacity>
            )
        else
            return null;
    }


    render() {
        return(
            <View style={[style.container, {backgroundColor: "white"}]}>
                {this.renderBoto('Home', this.state.recursos.find(e => e.tipus_recurs === '00_BotoMenu1')['imatge_recurs'].url )}
                {this.renderBoto('Itineraris', this.state.recursos.find(e => e.tipus_recurs === '00_BotoMenu2')['imatge_recurs' + this.state.idioma].url )}
                {this.renderBoto('Relats', this.state.recursos.find(e => e.tipus_recurs === '00_BotoMenu3')['imatge_recurs' + this.state.idioma].url)}
                {this.renderBoto('Joc3', this.state.recursos.find(e => e.tipus_recurs === '00_BotoMenu4')['imatge_recurs' + this.state.idioma].url)}
                {this.renderBoto('Perfil', this.state.recursos.find(e => e.tipus_recurs === '00_BotoMenu5')['imatge_recurs' + this.state.idioma].url )}
            </View>
        )
    }
}

//this.state.navigation.navigate('Prova',this.state)