import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    container: {
        flex: 1,
    },
    imageBackground: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
        paddingBottom: 15, 
        alignItems: 'center',
    },
    
    questionBackground: {
        width: "80%",
        height: "80%",
        justifyContent: "center",
        // backgroundColor: "#F8F4D3",
        borderRadius: 35
    },
    text: {
        fontSize: 15,
        textAlign: "justify",
        fontFamily: 'OpenSans',
    },
    titolContainer: {
        backgroundColor: "#6360a7",
        paddingTop: 15,
        paddingBottom: 15,
        width: "70%",
        textAlign: "center",
        alignSelf: 'center',
    },
    value: {
        fontWeight: '500',
        fontSize: 32,
        color: '#3FE3EB'
    },
    popUpButton: {
        marginTop: 20,
        paddingLeft: 15,
        paddingRight: 15,
        height: 50,
        backgroundColor: "#6360a7",
        justifyContent:'center'
    },
    popUpButtonText: {
        color: "white",
        fontSize: 23,
        fontWeight: "bold"
    },
    question: {
        // color: 'white',
        fontSize: 25,
        fontWeight: "bold",
        textAlign: "center",
        color:'white'
    },
    boxContinuar: {paddingHorizontal:15, height: 50, backgroundColor: "#3f3b22", alignItems: 'center', justifyContent: 'center',borderRadius:5},

    /* Modal */
    modalOutBackground: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
        // position:'absolute',
        // top:0,
        // bottom:0,
        // left:0,
        // right:0,
    },
    caixaInicial: {
        display: "flex",
        height: "80%",
        width: "70%",
        borderColor: "#6360a7",
        borderStyle: "solid",
        borderWidth: 4,
        borderRadius:5,
    },

    popUpTitleInicial: { //=> FONT PER COPIAR 
        color: "white",
        fontSize: 28,
        textAlign: "center",
        fontWeight: "bold",
        lineHeight: 28,
        fontFamily:'GOTHICB',
        textShadowColor:'black',
        textShadowOffset: {width: -1, height: 1},
    },

});