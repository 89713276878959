import { StyleSheet } from 'react-native';
const colorCorporatiu = '#715f4d';


const style = StyleSheet.create({
    textLoginPlaceholder: {
        fontSize: 26,
        width: '70%',
        height: 40,
        alignSelf: 'center',
        backgroundColor: 'white',
        borderRadius: 1,
        textAlign: 'left',
        fontWeight: 'bold',
        //default
        //borderColor: '#891812',
        //talpó
        color: '#715f4d',
        paddingLeft: 10
    },
    textLogin: {
        fontFamily: 'OpenSans', //=>
        fontSize: 20,
       // color: '#b73b53',
        padding: 5,
        paddingHorizontal:10,
        alignSelf: 'center',
        //previ:
        backgroundColor: '#6360a7',
        color:'white',
        //talpó
       // backgroundColor: 'transparent',
        fontWeight: 'bold'
    },
    textNom: {
        fontSize: 26,
        width: '80%',
        height: 40,
        alignSelf: 'center',
        borderRadius: 1,
        textAlign: 'left',
        fontWeight: 'bold',
        //default
        //borderColor: '#891812',
        //talpó
        backgroundColor: 'white',
        color: '#6360a7',
        paddingLeft: 10
    },
    imageBackground: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center"
    },
    caixaOut: {
        marginHorizontal: '10%',
        borderRadius: 10,
        flex: 1
    },
    caixa: {
        flex: 1,
        borderColor: '#edefe9',
        marginBottom: 10,
        marginTop: 10,
        marginLeft: 10,
        marginRight: 10,
    },
    container: {
        flex: 1,
        width: '100%',
        height: '100%'
    },

    imageBackground: {
        flex: 1,
        justifyContent: "center",
    },
    imatge: {
        height: 100,
        width: 100,
        resizeMode: "center",
        justifyContent: "center",
    },
    text: {
        //default
        //color: "black",
        //talpo
        color: "#715f4d",
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 10,
    },
    titol: {
        // color: "white",
        fontSize: 25,
        textAlign: "center",
        paddingTop: 20,
        paddingBottom: 30,
        color: "white",
        fontWeight: 600,
        fontSize: 36,
    },
    nom: {
        // color: "white",
        fontSize: 25,
        textAlign: "center",
        paddingTop: 20,
        paddingBottom: 30,
        color: "white",
        fontWeight: 600,
        fontSize: 31,
    },
    puntsText: {
        // color: "white",
        fontSize: 25,
        textAlign: "center",
        color: "black",
        fontWeight: 700,
        fontSize: 20,
        alignSelf: 'center'
    },
    text1: {
        // color: "white",
        fontSize: 25,
        textAlign: "center",
        color: "white"
    },
    text2: {
        // color: "white",
        fontSize: 60,
        fontWeight: "bold",
        textAlign: "center",
        color: "white",
        paddingTop: 6,
        paddingBottom: 20,
    },
    imatge: {
        height: null,
        width: null,
        resizeMode: "center",
        justifyContent: "center",
        flex: 0.7,
    }
});

export default style; 