import React from 'react';
import {
    SafeAreaView,
    FlatList,
    View,
    Text,
    ImageBackground,
    TouchableOpacity,
    Image,
    Dimensions,
    ScrollView, TextInput
} from 'react-native';
import style from './style';
import api from '../../config/api_urls'
import {gameId} from '../../Mapa/SampleData';
import Menu from "../../menu/menu";
import BounceLoader from "react-spinners/BounceLoader";
import ReactMarkdown from 'react-markdown'

export default class Relat extends React.Component {

    constructor(props) {
        super(props);
        this.state = props.route.params;
        this.state["loading"] = false;
        this.state["showContinue"] = false;
        this.state['interval'] ;
        
        this.state['loaded'] = false;
    }

    componentDidMount() {
        this.reiniciarRespostes()
    }

    reiniciarRespostes() {
        this.setState({
            resposta: ""
        })
    }

    guardarRespostes() {
        //guardar variables al localstorage
        let respostes = {
            resposta: this.state.resposta
        }

        //['observacio'] = respostes

        let poiIndex = this.state.currentStateItinerari.pois.findIndex(poi => poi.id === this.state.gameActive.id);
        if(poiIndex === -1 ) console.error("Error al guardar respostes (Escrit), no recull correctament l'index del poi");

        this.state.currentStateItinerari.pois[poiIndex]['respostesJocs']['Escrit'] ?
            this.state.currentStateItinerari.pois[poiIndex]['respostesJocs']['Escrit'] = respostes :
            console.error("Error en l'estructura de respostes del poi (Escrit)");
        localStorage.setItem('itinerari' + this.state.itinerariActual.id, JSON.stringify(this.state.currentStateItinerari))

    }

    _validar() {
        this.setState({loading: true})
        setTimeout(function() { //Start the timer
            this.setState({validar: true});
            this.setState({loading: false})
        }.bind(this), 1000)
    }

    goPremi() {
        this.guardarRespostes()
        this.reiniciarRespostes()
        this.props.navigation.navigate('Premi', this.state);
    }

    _paintButton() {
        if(this.state.loading) {
            return(
                <BounceLoader size={50} color={"#715f4d"} loading={this.state.loading} speedMultiplier={1.5} />
            )
        }
        else if(!this.state.loading && !this.state.validar){
            return(
                <TouchableOpacity style={style.boxContinuar} onPress={() => {
                    this._validar()
                }}>
                    <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs === 'text_guardar')['text_recurs' + this.state.idioma]}</Text>
                </TouchableOpacity>
            )
        }
        else if(!this.state.loading && this.state.validar){
            return(
                <TouchableOpacity style={style.boxContinuar} onPress={() => {
                    this.goPremi()
                }}>
                    <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs === 'text_continuar')['text_recurs' + this.state.idioma]}</Text>
                </TouchableOpacity>
            )
        }
    }

    render(){
        return(
            <View style={style.container} >
                <View style={{ flex: 15}}>
                    <ImageBackground source={{ uri: api.api_url + this.state.itinerariActual['fons_joc'].url}} style={style.imageBackground}>
                        <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.25, width:"80%", alignItems: 'center', justifyContent: 'center'}}>
                            <ImageBackground source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'relat_panell')['imatge_recurs'].url}}
                                             resizeMode="contain" style={style.questionBackground}>
                                <View style={{position: 'absolute', top: 0, left: 20, right: 20, bottom: 0, justifyContent: 'center', alignItems: 'center'}}>
                                    <Text style={style.descripcio}>{this.state.jocActiu["descripcioDetallada" + this.state.idioma]}</Text>
                                </View>
                            </ImageBackground>
                        </View>
                        <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.50, width: "80%", fontFamily: 'OpenSans', fontSize: 16, textAlign: "center", fontWeight: 500, paddingTop:20}}>
                            <TextInput
                                multiline={true}
                                style={style.answerInput}
                                numberOfLines={4}
                                onChangeText={(text) => this.setState({resposta: text})}
                                value={this.state.resposta}/>
                        </View>
                        <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.15, justifyContent: 'center'}}>
                            {this._paintButton()}
                        </View>
                        <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.10}}>
                        </View>
                    </ImageBackground>
                </View>
                <View style={{ flex: 2, bottom: 0, left: 0, right: 0, position: 'fixed', }}>
                    
                        <Menu projecte={this.state} ></Menu>
                </View>
            </View>
        )
        
    } 
}
