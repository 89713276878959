import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column"
    },
    imageBackground: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
        paddingBottom: 15,
        alignItems: 'center',
    },
    text: {
        fontSize: 15,
        textAlign: "justify",
        fontFamily: 'OpenSans',
    },
    titolContainer: {
        backgroundColor: "#a88f40",
        paddingTop: 15,
        paddingBottom: 15,
        width: "70%",
        textAlign: "center",
        alignSelf: 'center',
    },
    descriptionContainer: {
        width: "80%",
        textAlign: "center",
        alignSelf: 'center',
        fontFamily: 'OpenSans',
        fontSize: "2.2vh",
        color: "#3d342a"
    },
    titol: {
        fontSize: 30,
        textAlign: "center",
        fontWeight: "bold",
        width: '100%',
        alignSelf: 'center',
        color: "white"
    },
    subtitol: {
        fontSize: 20,
        textAlign: "center",
        fontWeight: "500",
        width: '100%',
        alignSelf: 'center',
        color: "white"
    },
    descripcio: {
        // color: '#715f4d',
        fontSize: 16,
        textAlign: "justify",
        fontWeight: 500,
        lineHeight: 20,
        fontFamily: 'OpenSans',
    },
    paragraph: {
        color: '#715f4d',
        fontSize: 16,
        textAlign: "justify",
        fontWeight: 500,
        lineHeight: 20,
        fontFamily: 'OpenSans'
    },
    questionBackground: {
        width: "100%",
        height: "90%",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        alignSelf: 'center'
    },
    score1: {
        color: '#a88f40',
        fontSize: 18,
        fontWeight: "bold",
        textAlign: "center"
    },
    score2: {
        color: '#715f4d',
        fontSize: 22,
        fontWeight: "bold",
        textAlign: "center",
        marginTop: -5
    },
    score3: {
        color: 'white',
        fontSize: '1.8rem',
        fontWeight: "bold",
        textAlign: "center"
    },
    score4: {
        color: 'white',
        fontSize: '1.3rem',
        fontWeight: "bold",
        textAlign: "center",

    },
    TouchableOpacity: {
        height: "100%",
        width: "100%",
        position: "absolute"
    },
    containerAnswer: {
        backgroundColor: '#a88f40',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: "100%",
        width: "100%",
    },
    containerAnswerBack: {
        backgroundColor: '#F8F4D3',
        justifyContent: 'center',
        alignItems: 'center',
        height: "100%",
        width: "100%",
    },
    answer: {
        color: "white",
        fontWeight: "bold",
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 20
    },
    answerBack: {
        color: "#a88f40",
        fontWeight: "bold",
        fontSize: 15
    },
    talpSolucio: {
      height: 70,
      width: 70
    },
    containerContinue: {
        paddingLeft: 15,
        paddingRight: 15,
        height: 40,
        backgroundColor: '#6360a7',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius:5

    },
    boxContinuar:{
        paddingHorizontal:15,
        height: 50,
        backgroundColor: "#6360a7",
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius:5,
    },
    continue: {
        color: "white",
        fontWeight: "bold",
        fontSize: 15,
        backgroundColor: '#6360a7',
    },
    safeAreaAnswer: {
        width: "70%",
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardContainer: {
        height: 150,
        width: 150,
        margin: 4,
        justifyContent: 'center',
        alignItems: 'center',
    }
});