import React from 'react';
import {View, Text, ImageBackground, TouchableOpacity, Image, Dimensions} from 'react-native';
import style from './style';
import api from '../config/api_urls'
import Menu from "../menu/menu";
import ReactMarkdown from 'react-markdown'
import {postStadistic} from "../../helpers/petitions";
import { ScrollView } from 'react-native-web';

const imageWith = Dimensions.get('screen').width * 110/250;
const imageHeight = Dimensions.get("screen").height * 25/250;

export default class InfoItinerari extends React.Component {

    constructor(props) {
        super(props);
        this.state = props.route.params;
    }

    goMapa() {
        postStadistic({token: this.state.user_token, tipus_peticio: "Inici itinerari", peticio: "Inici itinerari " + this.state.itinerariActual.Nom})
        this.props.navigation.navigate('Mapa', this.state)
    }
    
    _renderBotoContinuar()
    {
        if(!this.state.itinerariActual["boto_continuar"])
        {
            return (<Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs == 'text_continuar')["text_recurs" + this.state.idioma]}</Text>)
        }else
        {
            return (<Image style={{width : imageWith ,height: imageHeight , alignSelf:'center',resizeMode:'contain',paddingBottom:5}} source={{uri: api.api_url + this.state.itinerariActual["boto_continuar"+this.state.idioma].url}} />)

        }
    }

    render(){
        return(
            <View style={{flex: 1}}>
                <View style={{flex: 15}}>
                    <ImageBackground source={{uri: api.api_url + this.state.itinerariActual['fons'].url}} style={style.imageBackground}>
                        <View style={{height: "100%", width: "100%", position: "absolute"}}>
                            <View style={{flex: 16, justifyContent: "center", alignItems: "center"}}>
                                <View style={style.titolContainer}>
                                    <Text style={style.titolGarraf}>{this.state.itinerariActual["Nom" + this.state.idioma]}</Text>
                                    <Text style={style.subtitol}>{this.state.itinerariActual["descripcio" + this.state.idioma]}</Text>
                                </View>
                            </View>
                            <View style={[style.caixa,{flex: 55,paddingBottom:5}]}>
                                <ScrollView>
                                    <View style={{ justifyContent: "center", alignItems: "center"}}>
                                    </View>
                                    <View style={[style.descriptionContainer,{fontSize: 16,textAlign: "center",}]}>
                                        <ReactMarkdown style={[style.descripcio]}>{this.state.itinerariActual["descripcio2Detallat" + this.state.idioma]}</ReactMarkdown>
                                    </View>
                                </ScrollView>
                            </View>
                            <View style={{flex:25}}>
                                    <Image style={style.jocs} source={{uri: api.api_url + this.state.itinerariActual['imatge_descripcio_itinerari'].url}} />
                            </View>
                            <View style={{flex: 10, justifyContent: "center", alignItems: "center"}}>
                                <TouchableOpacity  onPress={() => {
                                    this.goMapa()
                                }} style={{paddingBottom:10}}>
                                    {
                                        this._renderBotoContinuar()
                                    }
                                </TouchableOpacity>
                            </View>
                           
                            <View style={{flex: 10}} />
                        </View>
                    </ImageBackground>
                </View>
                <View style={{ flex: 2, bottom: 0, left: 0, right: 0, position: 'fixed', }}>
                    <Menu projecte={this.state}></Menu>
                </View>
            </View>
        )
    }
}