import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column"
    },
    video: {
        height: '100%',
        width: '100vw',
        resizeMode: 'cover',
        objectFit: 'cover',
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: -1
    },
    textInici: {
        fontSize:22,
        fontFamily: 'Gothic',
    },
    descriptionContainer: {
        width: "80%",
        textAlign: "center",
        alignSelf: 'center',
        fontFamily: 'OpenSans',
        fontSize: "2.2vh",
        color: "#3d342a"
    },
    descripcio: {
        color: '#715f4d',
        fontSize: 16,
        textAlign: "justify",
        fontWeight: 500,
        lineHeight: 20,
        fontFamily: 'OpenSans',
    },
    imageBackground: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
    },
    image: {
        resizeMode: "center",
        alignSelf: "center",
        marginBottom: 200,
        marginTop: 50
    },
    text: {
        color: "white",
        fontSize: 42,
        fontWeight: "bold",
        textAlign: "center",
        backgroundColor: "#000000a0",
    },
    TouchableOpacity: {
        position: "absolute",
        top: 1,
        right: 1,
        left: 1,
        bottom: 1
    },
    containerContinue: {
        height: 50,
        backgroundColor: '#000000',
        justifyContent: 'end',

        alignItems: 'center',
        width: 'fitContent'
    },
    continue: {
        fontFamily: 'OpenSans',
        fontSize: 20,
        paddingTop: 11,
        paddingBottom: 11,
        paddingLeft: 25,
        paddingRight: 25,
        paddingHorizontal: 10,
        alignSelf: 'center',
        backgroundColor: '#6360a7',
        color: 'white',
        fontWeight: 'bold'
    },
    crossBox: {
        position: 'absolute',
        right: 6,
        top: 6
    },
    crossImage: {
        height: 30,
        width: 30
    }
});
