import React from 'react';
import { View, Text, ImageBackground, TouchableOpacity, Image, Dimensions, ScrollView} from 'react-native';
import style from './style';
import api from '../../config/api_urls'
import Menu from "../../menu/menu";
import ReactMarkdown from 'react-markdown'

export default class Premi extends React.Component {

    constructor(props) {
        super(props);
        this.returnMapControl = false
        this.state = props.route.params;
        this.state["showQuizz"] = false;
        this.state["showContinue"] = false;
        this.state["load"] = false;
        this.puntsAconseguits = this.state.puntsAconseguits
        //retard d'un segon per evitar bug de react que carrega la funció que se li passa per render
        this.carregarPremi(this.state.jocActiu.premi)
        this.state.puntsProva = 1
        this.state.puntsTotalsAconseguits = parseInt(this.state.currentStateItinerari.puntsTotalsAconseguits) + parseInt(this.state.puntsProva)
        this.puntsTotals = parseInt(localStorage.getItem('puntsTotals')) + parseInt(this.state.puntsProva);
    }

    carregarPremi = (idPremi) => {
        this.state.currentPremi = this.state.projecte.premis.find(e => e.id === idPremi)
    }

    returnMap = () => {
        if(!this.returnMapControl) {
            this.returnMapControl = true;
            // setTimeout(function () {
                this.state.currentStateItinerari.pois.find(e => e.id === this.state.poi.id).jocs[this.state.jocActiu.Tipus] = true
                this.state.currentStateItinerari.puntsTotalsAconseguits = parseInt(this.state.currentStateItinerari.puntsTotalsAconseguits) + parseInt(this.state.puntsProva)
                localStorage.setItem('puntsTotals', this.puntsTotals);
                if(
                    this.state.currentStateItinerari.pois.find(e => e.id === this.state.poi.id).jocs["Moviment"] == true &&
                    this.state.currentStateItinerari.pois.find(e => e.id === this.state.poi.id).jocs["Meditacio"] == true &&
                    this.state.currentStateItinerari.pois.find(e => e.id === this.state.poi.id).jocs["Observacio"] == true &&
                    this.state.currentStateItinerari.pois.find(e => e.id === this.state.poi.id).jocs["Escrit"] == true 
                )
                {
                    this.state.currentStateItinerari.pois.find(e => e.id === this.state.poi.id).done = true 
                    if(this.checkOrder())
                    {
                        this.state.currentStateItinerari.currentOrder++
                    }
                    this.state.navigation.navigate('Mapa', this.state);
                }else
                {
                    this.state.navigation.navigate('MenuJocs', this.state);
                }
                localStorage.setItem('itinerari' + this.state.itinerariActual.id, JSON.stringify(this.state.currentStateItinerari));
                
            // }.bind(this), 500)
        }
    }

    checkOrder()
    {
        let checkOrderDone = true
        let check = this.state.currentStateItinerari.pois.filter(e => e.order == this.state.currentStateItinerari.currentOrder );
        check.forEach(element => {
            if(element.done == false)
            {
                checkOrderDone = false
            }
        });

        return checkOrderDone;
    }

    render(){
        return(
            <View style={{flex: 1}}>
                <View style={{flex: 15}}>
                    <ImageBackground source={{ uri: api.api_url + this.state.itinerariActual['fons_joc'].url}} style={style.imageBackground}>
                        <View style={{flex: 20, justifyContent: 'center', alignItems: 'center', width: "70%"}}>
                            <ImageBackground source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'bocata_premi')['imatge_recurs'].url}} resizeMode="contain" style={style.questionBackground}>
                                {/* <View style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center'}}> */}
                                    {/* <Text style={style.score1}>{this.state.recursos.find(e => e.tipus_recurs === 'guanyar')['text_recurs' + this.state.idioma]}</Text>
                                    <Text style={style.score2}>{this.state.puntsProva}/{this.state.currentPremi.punts} {this.state.recursos.find(e => e.tipus_recurs === 'punts')['text_recurs' + this.state.idioma]}</Text> */}
                                    <Text style={style.score3}>{this.state.recursos.find(e => e.tipus_recurs === 'total_itinerari')['text_recurs' + this.state.idioma]}</Text>
                                    <Text style={style.score4}>{this.state.puntsTotalsAconseguits}/{20} </Text>{/*//=> //todo canviar el 4 hardcoded */}
                                 {/* </View> */}
                            </ImageBackground>
                        </View>
                        <View style={{flex: 25, justifyContent: 'center', alignItems: 'center',textAlign:'center'}}>
                            <View style={{height: "100%", width: Dimensions.get('screen').height * (13 / 15) * 0.30, alignItems: "center", resizeMode: 'contain', justifyContent: "center", textAlign: "center"}} >
                                <Image style={{height: Dimensions.get('screen').height * (13 / 15) * 0.29, width: Dimensions.get('screen').height * (13 / 15) * 0.29, resizeMode: 'contain'}} source={{uri: api.api_url + this.state.currentPremi.Premi.url}} />
                            </View>
                        </View>
                        <ScrollView style={{flex:15,}}>
                            <View style={[style.descriptionContainer, {justifyContent: "center", textAlign: "center", alignSelf: 'center'}]}>
                                <ReactMarkdown style={style.descripcio}>{(this.state.currentPremi["descripcioPremiDetallat" +  this.state.idioma]) ?? (this.state.currentPremi["descripcioPremiDetallat" +  this.state.idioma])}</ReactMarkdown>
                            </View>
                        </ScrollView>
                        <View style={{flex: 10, justifyContent: 'center',}}>
                        <TouchableOpacity style={style.boxContinuar} onPress={() => {
                            this.returnMap() 
                        }}>
                            <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs === 'text_continuar')['text_recurs' + this.state.idioma]}</Text>
                        </TouchableOpacity>
                        </View>
                        <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.10, justifyContent: "center"}} />
                    </ImageBackground>
                </View>
                <View style={{ flex: 2, bottom: 0, left: 0, right: 0, position: 'fixed', }}>
                    <Menu projecte={this.state}></Menu>
                </View>
            </View>

        )
    }
}
