import {
    StyleSheet
} from 'react-native';

export default StyleSheet.create({
    modalOutBackground: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    caixa: {
        display: "flex",
        height: "60%",
        width: "70%",
        borderColor: "#6360a7",
        borderStyle: "solid",
        borderWidth: 4,
        borderRadius:5,
    },
    caixaInicial: {
        display: "flex",
        height: "80%",
        width: "70%",
        borderColor: "#6360a7",
        borderStyle: "solid",
        borderWidth: 4,
        borderRadius:5,
    },
    caixaFinal: {
        display: "flex",
        height: "80%",
        width: "70%",
        borderColor: "#6360a7",
        borderStyle: "solid",
        borderWidth: 6
    },
    crossBox: {
        position: 'absolute',
        right: 6,
        top: 6,
    },
    cross: {
        color: "white",
        fontSize: 20
    },
    crossImage: {
        height: 30,
        width: 30
    },
    popUpTitle: {
        color: "#715f4d",
        fontSize: 28,
        textAlign: "center",
        fontWeight: "bold",
        lineHeight: 28,
        paddingBottom: 28
    },
    popUpTitleInicial: { //=> FONT PER COPIAR 
        color: "white",
        fontSize: 28,
        textAlign: "center",
        fontWeight: "bold",
        lineHeight: 28,
        fontFamily:'GOTHICB',
        textShadowColor:'black',
        textShadowOffset: {width: -1, height: 1},
    },
    popUpSubtitle: {
        color: "#252525",
        fontSize: 20,
        textAlign: "center",
        fontWeight: "bold",
        paddingBottom:10,

    },
    popUpSubtitleConfeti: {
        color: "#252525",
        fontSize: 20,
        textAlign: "center",
        fontWeight: "bolder",

    },
    popUpIndicatorText: {
        color: "#6360a7",
        fontSize: 23,
        fontWeight: "bold",
        textAlign: "center",
        width:'80%'
    },
    popUpButton: {
        marginTop: 20,
        paddingLeft: 15,
        paddingRight: 15,
        height: 50,
        backgroundColor: "#6360a7",
        justifyContent:'center'
    },
    popUpButtonText: {
        color: "white",
        fontSize: 23,
        fontWeight: "bold"
    },
    imageBackground: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
        paddingBottom: 15,
        alignItems: 'center',
    },
});