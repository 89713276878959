import React from 'react';
import { View, Text, ImageBackground, Image, ScrollView,Dimensions } from 'react-native';
import style from './style';
import api from '../config/api_urls';
import Menu from '../menu/menu';
import ReactMarkdown from 'react-markdown';
import AsyncStorage from '@react-native-community/async-storage';


export default class Relats extends React.Component {
    state = {};
    constructor(props) {
        super(props); 
        this.state = props.route.params;
        // console.log(this.state.idioma)
        let idioma = this.state.idioma.replace(/_/g, "")
        idioma = idioma.toLowerCase();
        if(idioma === "") idioma = "cat"

        this.relats = this.state.relats.filter( x => {
            return (x.usuari.toLowerCase() === idioma)
        });
    }

   render() {
        return (
            <View style={{ flex: 1 }}>
                <View style={{ flex: 15 }}>
                    <ImageBackground source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '05_background_tipus4').imatge_recurs.url}} style={style.imageBackground}>

                        <View style={{height: 80, alignItems: 'left', justifyContent: 'left'}}>
                            <ImageBackground source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'observacio_panell')['imatge_recurs'].url}}
                                             resizeMode="contain" style={style.questionBackground}>
                                <View style={{position: 'absolute', top: 0, left: 20, right: 20, bottom: 0, justifyContent: 'center', alignItems: 'center'}}>
                                    <Text style={style.titolReq}>{this.state.recursos.find(e => e.tipus_recurs === 'relats')['text_recurs' + this.state.idioma]}</Text>
                                </View>
                            </ImageBackground>
                        </View>

                        <ScrollView style={{height: Dimensions.get('screen').height * (13 / 15) * 0.50}}>
                            {this.relats.map((relat, index) => {
                                if( relat.img_url) {
                                    return (<View style={{paddingLeft: "10%", paddingRight: "10%", paddingTop: 30}}>
                                        <Image resizeMode="contain" style={{width: "100%", height: 250}} source={{uri: api.api_cenobify + "\/" + relat.img_url}} />
                                        <View style={{flex: 1}}>
                                            <Text style={style.descripcio}>{relat.titol}</Text>
                                            <Text style={style.text}>{relat.relat_text}</Text>
                                        </View>

                                    </View>)
                                }
                                else {
                                    return(<View style={{paddingLeft: "10%", paddingRight: "10%", paddingTop: 30}}>
                                        <Text style={style.descripcio}>{relat.titol}</Text>
                                        <Text style={style.text}>{relat.relat_text}</Text>
                                    </View>)
                                }
                                }
                            )}
                            </ScrollView>
                        <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.10}} />
                    </ImageBackground>
                </View>
                <View style={{ flex: 2, bottom:0,left:0,right:0, position:'fixed', }}>
                    <Menu projecte={this.state}></Menu>
                </View>
            </View>
        )
    }
}