import React from 'react';
import {
    SafeAreaView,
    FlatList,
    View,
    Text,
    ImageBackground,
    TouchableOpacity,
    Image,
    Dimensions,
    ScrollView, TextInput
} from 'react-native';
import style from './style';
import api from '../../config/api_urls'
import {gameId} from '../../Mapa/SampleData';
import Menu from "../../menu/menu";
import BounceLoader from "react-spinners/BounceLoader";
import ReactMarkdown from 'react-markdown'

export default class Observacio extends React.Component {

    constructor(props) {
        super(props);
        this.state = props.route.params;
        this.state["loading"] = false;
        this.state["checkIn"] = false;
        this.state["showContinue"] = false;
        this.state['interval'];
    }

    componentDidMount() {
        this.reiniciarRespostes()
    }

    reiniciarRespostes() {
        this.setState({
            resposta1: "",
            resposta2: "",
            resposta3: "",
            resposta4: "",
            resposta5: ""
        })
    }

    guardarRespostes() {
        //guardar variables al localstorage
        let respostes = {
            resposta1: this.state.resposta1,
            resposta2: this.state.resposta2,
            resposta3: this.state.resposta3,
            resposta4: this.state.resposta4,
            resposta5: this.state.resposta5
        }

        //['observacio'] = respostes

        let poiIndex = this.state.currentStateItinerari.pois.findIndex(poi => poi.id === this.state.gameActive.id);
        if(poiIndex === -1 ) console.error("Error al guardar respostes, no recull correctament l'index del poi");

        this.state.currentStateItinerari.pois[poiIndex]['respostesJocs']['Observacio'] ?
            this.state.currentStateItinerari.pois[poiIndex]['respostesJocs']['Observacio'] = respostes :
            console.error("Error en l'estructura de respostes del poi");
        localStorage.setItem('itinerari' + this.state.itinerariActual.id, JSON.stringify(this.state.currentStateItinerari))

    }

    goPremi() {
        this.guardarRespostes();
        this.reiniciarRespostes();
        this.props.navigation.navigate('Premi', this.state);
    }

    _validar() {
        this.setState({loading: true})
        setTimeout(function() { //Start the timer
            this.setState({validar: true});
            this.setState({loading: false})
        }.bind(this), 1000)
    }

    _paintButton() {
        if(this.state.loading) {
            return(
                <BounceLoader size={50} color={"#715f4d"} loading={this.state.loading} speedMultiplier={1.5} />
            )
        }
        else if(!this.state.loading && !this.state.validar){
            return(
                <TouchableOpacity style={style.boxContinuar} onPress={() => {
                    this._validar()
                }}>
                    <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs === 'text_validar')['text_recurs' + this.state.idioma]}</Text>
                </TouchableOpacity>
            )
        }
        else if(!this.state.loading && this.state.validar){
            return(
                <TouchableOpacity style={style.boxContinuar} onPress={() => {
                    this.goPremi()
                }}>
                    <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs === 'text_continuar')['text_recurs' + this.state.idioma]}</Text>
                </TouchableOpacity>
            )
        }
    }
    

    render(){
        return(
            <View style={style.container} >
                <View style={{ flex: 15, width: "100%"}}>
                    <ImageBackground source={{ uri: api.api_url + this.state.itinerariActual['fons_joc'].url}} style={style.imageBackground}>
                        <View style={{width: "100%", alignItems: 'center', flex: "inherit" }}>
                            <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.08, width:"80%", alignItems: 'center', justifyContent: 'center', marginTop: 10, marginBottom: 10}}>
                                <ImageBackground source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'observacio_panell_icona')['imatge_recurs'].url}}
                                                 resizeMode="contain" style={style.questionBackground}>
                                    <View style={{position: 'absolute', top: 12, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={style.descripcio}>{this.state.recursos.find(e => e.tipus_recurs === 'observacio_5coses')['text_recurs' + this.state.idioma]}</Text>
                                    </View>
                                </ImageBackground>
                            </View>
                            <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.11, width: "70%", alignItems: 'center', justifyContent: 'center', marginTop: 5, marginBottom: 5, marginLeft: 6, marginRight: 6}}>
                                <TextInput
                                    multiline={true}
                                    style={style.answerInput}
                                    numberOfLines={4}
                                    onChangeText={(text1) => this.setState({resposta1: text1})}
                                    value={this.state.resposta1}/>
                            </View>

                            <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.08, width:"80%", alignItems: 'center', justifyContent: 'center'}}>
                                <ImageBackground source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'observacio_panell')['imatge_recurs'].url}}
                                                 resizeMode="contain" style={style.questionBackground}>
                                    <View style={{position: 'absolute', top: 0, left: 20, right: 20, bottom: 0, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={style.descripcio}>{this.state.recursos.find(e => e.tipus_recurs === 'observacio_4coses')['text_recurs' + this.state.idioma]}</Text>
                                    </View>
                                </ImageBackground>
                            </View>
                            <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.11, width: "70%", alignItems: 'center', justifyContent: 'center'}}>
                                <TextInput
                                    multiline={true}
                                    style={style.answerInput}
                                    numberOfLines={4}
                                    onChangeText={(text2) => this.setState({resposta2: text2})}
                                    value={this.state.resposta2}/>
                            </View>

                            <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.08, width:"80%", alignItems: 'center', justifyContent: 'center'}}>
                                <ImageBackground source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'observacio_panell')['imatge_recurs'].url}}
                                                 resizeMode="contain" style={style.questionBackground}>
                                    <View style={{position: 'absolute', top: 0, left: 20, right: 20, bottom: 0, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={style.descripcio}>{this.state.recursos.find(e => e.tipus_recurs === 'observacio_3coses')['text_recurs' + this.state.idioma]}</Text>
                                    </View>
                                </ImageBackground>
                            </View>
                            <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.11, width: "70%", alignItems: 'center', justifyContent: 'center'}}>
                                <TextInput
                                    multiline={false}
                                    style={style.answerInput}
                                    numberOfLines={4}
                                    onChangeText={(text3) => this.setState({resposta3: text3})}
                                    value={this.state.resposta3}/>
                            </View>

                            <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.08, width:"80%", alignItems: 'center', justifyContent: 'center'}}>
                                <ImageBackground source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'observacio_panell')['imatge_recurs'].url}}
                                                 resizeMode="contain" style={style.questionBackground}>
                                    <View style={{position: 'absolute', top: 0, left: 20, right: 20, bottom: 0, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={style.descripcio}>{this.state.recursos.find(e => e.tipus_recurs === 'observacio_2coses')['text_recurs' + this.state.idioma]}</Text>
                                    </View>
                                </ImageBackground>
                            </View>
                            <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.08, width: "70%", alignItems: 'center', justifyContent: 'center'}}>
                                <TextInput
                                    multiline={true}
                                    style={style.answerInput}
                                    numberOfLines={1}
                                    onChangeText={(text4) => this.setState({resposta4: text4})}
                                    value={this.state.resposta4}/>
                            </View>

                            <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.08, width:"80%", alignItems: 'center', justifyContent: 'center'}}>
                                <ImageBackground source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'observacio_panell')['imatge_recurs'].url}}
                                                 resizeMode="contain" style={style.questionBackground}>
                                    <View style={{position: 'absolute', top: 0, left: 20, right: 20, bottom: 0, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={style.descripcio}>{this.state.recursos.find(e => e.tipus_recurs === 'observacio_1coses')['text_recurs' + this.state.idioma]}</Text>
                                    </View>
                                </ImageBackground>
                            </View>
                            <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.08, width: "70%", alignItems: 'center', justifyContent: 'center'}}>
                                <TextInput
                                    style={style.answerInput}
                                    numberOfLines={1}
                                    onChangeText={(text5) => this.setState({resposta5: text5})}
                                    value={this.state.resposta5}/>
                            </View>
                            <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.10, justifyContent: 'center'}}>
                                {this._paintButton()}
                            </View>
                            <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.10}}>
                            </View>
                        </View>
                    </ImageBackground>
                </View>
                <View style={{ flex: 2, bottom: 0, left: 0, right: 0, position: 'fixed', }}>
                        <Menu projecte={this.state} ></Menu>
                </View>
            </View>
        )
        
    } 
}
