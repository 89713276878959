import React from 'react';
import {
    SafeAreaView,
    FlatList,
    View,
    Text,
    ImageBackground,
    TouchableOpacity,
    Image,
    Dimensions,
    ScrollView
} from 'react-native';
import style from './style';
import api from '../../config/api_urls'
import Menu from "../../menu/menu";
import ReactMarkdown from 'react-markdown';

export default class AutoTestFinal extends React.Component {

    constructor(props) {
        super(props);
        this.state = props.route.params;
        this.state['caraUrl'] = ''
    
    }

    componentDidMount()
    {
        this.getEmogiAutotest();
    }

    getEmogiAutotest()
    {
        let retrievedObject = localStorage.getItem('autoTest');
        // console.log(retrievedObject);
        if(retrievedObject == null || retrievedObject == undefined)
        {
            this.state.caraUrl = this.state.recursos.find(e => e.tipus_recurs === 'Autotest_3').imatge_recurs.url
        }else
        {   
            // console.log("ENTRA !!");
            let punts = 0;
            let questions = 0;
            let object = JSON.parse(retrievedObject)
            object.forEach((element,i) => { 
                    punts =  parseInt(element.points) + punts
                    questions ++;
               });
            
    
            switch(Math.round(punts/questions))
            {
                case 0: this.state.caraUrl = this.setState({caraUrl : this.state.recursos.find(e => e.tipus_recurs === 'Autotest_3').imatge_recurs.url}); break;
                case 1: this.state.caraUrl = this.setState({caraUrl : this.state.recursos.find(e => e.tipus_recurs === 'Autotest_2').imatge_recurs.url}); break;
                case 2: this.state.caraUrl = this.setState({caraUrl : this.state.recursos.find(e => e.tipus_recurs === 'Autotest_1').imatge_recurs.url}); break;
                case 3: this.state.caraUrl = this.setState({caraUrl : this.state.recursos.find(e => e.tipus_recurs === 'Autotest_0').imatge_recurs.url}); break;
            }
            // console.log('====================================');
            // console.log(this.state.caraUrl);
            // console.log('====================================');
        }
    }
 
    render(){

        return(
        <View style={{ flex: 1}}>       
            <ImageBackground source={{ uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '06_background_pantalles_generic').imatge_recurs.url }} style={style.imageBackground}>
                <View style={{flex:15}}></View>
                    <View style={{flex:10,alignItems:'center',justifyContent:'center',width:'80%'}}>
                        <Text style={style.question}>{this.state.recursos.find(e => e.tipus_recurs == 'salut_emocional')["text_recurs"+this.state.idioma]}</Text>
                    </View>
               
                   
                    <View style={{flex:35}}>
                        <Image style={{resizeMode: "contain", width: "90%", height: "60%",justifyContent:'center',alignSelf:'center'}} source={{uri: api.api_url + this.state.caraUrl}} />
                        <View style={style.text}>
                            <ReactMarkdown >{this.state.recursos.find(e => e.tipus_recurs == 'autotest_text')["rich_text_recurs"+this.state.idioma]}</ReactMarkdown>
                        </View>
                    </View>

                <View style={{flex: 15, alignItems:'center',justifyContent:'center'}}>
                    <TouchableOpacity style={style.popUpButton} onPress={() => {  this.state.navigation.navigate('Perfil', this.state); }}>
                        <Text style={style.popUpButtonText}>{this.state.recursos.find(e => e.tipus_recurs === 'text_continuar')['text_recurs' + this.state.idioma]}</Text>
                    </TouchableOpacity>
                </View> 
               <View style={{flex:10}}></View>
            </ImageBackground>
            <View style={{ flex: 2, bottom:0,left:0,right:0, position:'fixed', }}>
                <Menu projecte={this.state}></Menu>
            </View>
        </View>
        
        )
    }
  
}
