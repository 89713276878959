import React from 'react';
import {
    SafeAreaView,
    FlatList,
    View,
    Text,
    ImageBackground,
    TouchableOpacity,
    Image,
    Dimensions,
    ScrollView
} from 'react-native';
import style from './style';
import api from '../../config/api_urls'
import {gameId} from '../../Mapa/SampleData';
import Menu from "../../menu/menu";
import BounceLoader from "react-spinners/BounceLoader";
import ReactMarkdown from 'react-markdown'
import { Slide } from '@material-ui/core';

import CircularSlider from '@fseehawer/react-circular-slider';

import { ReactComponent as EmojiIcon } from './emogi';
export default class AutoTest extends React.Component {

    constructor(props) {
        super(props);
        this.state = props.route.params;
        this.state['color'] = 'red';
        this.state['value'];
        this.state['puntuacio'] = 0;
        this.state['currentQuestion'] = 0;
        this.state['currentAnswers'] = [];
        this.state['currentColors'] = [];
        this.state['currentPunts'] = [];
        this.state['loading'] = true;
    }

    changeColor(value)
    {
       let index = this.state.currentAnswers.indexOf(value);
       if(this.state.currentColors[index] != null && this.state.currentColors[index] != undefined )
       {
            this.setState({color : this.state.currentColors[index]})
            this.setState({puntuacio:this.state.currentPunts[index]})
       }
       this.setState({value:value})
    }

    componentDidMount()
    {
        this.renderAnswers();
        this.setState({color : this.state.currentColors[0]});
        this.setState({puntuacio : this.state.currentPunts[0]});
        setTimeout(()=>{this.setState({loading:false})},1000)
    }

    saveData()
    {
        let retrievedObject = localStorage.getItem('autoTest');
        if(retrievedObject != null && retrievedObject != undefined)
        {
            let object = JSON.parse(retrievedObject)
            let find = false;
            object.forEach((element,i) => {
             if(element.pregunta == this.state.currentQuestion)
             {
                object[i].value = this.state.value;
                object[i].points = this.state.puntuacio;
                find = true
             }    
            });
            if(!find)
            {
                object.push({"pregunta":this.state.currentQuestion,"value":this.state.value,"points":this.state.puntuacio})
            }
            localStorage.setItem('autoTest', JSON.stringify(object));
        }else
        {   
            localStorage.setItem('autoTest', JSON.stringify([{"pregunta":this.state.currentQuestion,"value":this.state.value,"points":this.state.puntuacio}]));     
        }

        this.nextquestion()
    }
    
    nextquestion()
    {
        let nextItem = parseInt(1 + this.state.currentQuestion)
        if(nextItem < this.state.projecte.perfils.length)
        {
            this.state.currentQuestion = nextItem 
            this.state.currentAnswers = [] 
            this.state.currentColors = []
            this.state.currentPunts = []
            this.setState({loading:true})
            this.renderAnswers()
        }else
        {
            this.props.navigation.navigate('AutoTestFinal', this.state);
        } 
    }

    renderAnswers()
    {
        this.state.projecte.perfils[this.state.currentQuestion]["Respostes"+this.state.idioma].respostes.forEach(element => {
           this.state.currentAnswers.push(element.resposta);
           this.state.currentColors.push(element.color)
           this.state.currentPunts.push(element.punts)
        });
        this.setState({color : this.state.currentColors[0]});
        this.setState({puntuacio : this.state.currentPunts[0]});
        // this.setState({loading:false})
        setTimeout(()=>{this.setState({loading:false})},1000)
    }
    _renderRuleta()
    {
        return(
            <View style={{flex:50,justifyContent:'center',alignItems:'center'}}>
            <CircularSlider
                label=" "
                min={0}
                max={10}
                data={this.state.currentAnswers}
                // dataIndex={20}
                labelColor={this.state.color}
                labelBottom={true}
                valueFontSize="1.2rem"
                knobColor="#6360a7"
                knobSize={Dimensions.get('screen').width * 50/250}
                progressColorFrom={this.state.color}
                progressColorTo={this.state.color}
                progressSize={24}
                trackColor="#eeeeee"
                trackSize={24}
                onChange={ value => { this.changeColor(value) } }
            >
            <EmojiIcon x='22' y='22' width='1px' height='1px' />
            </CircularSlider>

            <TouchableOpacity style={style.popUpButton} onPress={() => {this.saveData()}}>
                <Text style={style.popUpButtonText}>{this.state.recursos.find(e => e.tipus_recurs === 'text_continuar')['text_recurs' + this.state.idioma]}</Text>
            </TouchableOpacity>
            <View style={{flex:3, flexDirection:'row', alignItems:'center',alignSelf:'center',justifyContent:'flex-start'}}>
            </View>
        </View>
        )
    }

    render(){

        return(
        <View style={{ flex: 10}}>
            <ImageBackground source={{ uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '06_background_pantalles_generic').imatge_recurs.url }} style={style.imageBackground}>
                <View style={{flex: 15, justifyContent: 'center', alignItems: 'center', width: "100%"}}>
                    <View style={style.questionBackground}>
                    <ImageBackground style={{width:'100%',height:'100%',alignItems:'center',justifyContent:'center'}} resizeMode={'contain'}  source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == 'fons_text_default')['imatge_recurs'].url}}>

                        <View style={{ justifyContent: 'center', alignItems: 'center',width:'90%'}}>
                            <ScrollView>
                                <Text style={style.question}>{this.state.projecte.perfils[this.state.currentQuestion]["Pregunta"+this.state.idioma]}</Text>
                            </ScrollView>
                        </View>
                        </ImageBackground>

                    </View>
                </View>
                
                {!this.state.loading ? this._renderRuleta() : null}
            <View style={{ flex: 2, bottom:0,left:0,right:0, position:'fixed', }}>
                <Menu projecte={this.state}></Menu>
            </View>
            </ImageBackground>
        </View>
        )
    }
  
}
