import React from 'react';
import {View, Text, ImageBackground, TouchableOpacity, Image, Dimensions} from 'react-native';
import style from './style';
import api from '../../config/api_urls'
import Menu from "../../menu/menu";
import { DraxProvider, DraxView } from 'react-native-drax';


export default class DragAndDrop extends React.Component {

    constructor(props) {
        super(props);
        this.state = props.route.params;
        this.staged = []
        this.setStaged = []
        this.state["showQuizz"] = false
        this.state["showContinue"] = false
        this.state["load"] = false

        this.state.imageSrc1 = ""
        this.state.imageSrc2 = ""
        this.state.imageSrc3 = ""

        this.state.valid1 = 0
        this.state.valid2 = 0
        this.state.valid3 = 0

        this.state["complete"] = false


        //retard d'un segon per evitar bug de react que carrega la funció que se li passa per render
        //todo: trobar solució a aquest problema, potser actualitzant versió...
        setTimeout(function() { //Start the timer
            this.setState({ load: true });
        }.bind(this), 1000)
    }

    _goQuizz() {
        // console.log("trying to go quizz")
        if(this.state.load) {
            // console.log("go quizz")
            this.setState({showQuizz: true});
        }
    }

    checkInfo(data, tipus) {
        if(data.tipus === tipus) {
            return {imageUrl: data.url, imageValid: (data.valid) ? this.imageValidationValid : this.imageValidationInValid }
        }
        else {
            return {imageUrl: "", imageValid: "" }
        }
    }

    _renderDraxElements() {
        let items = []
        for (const value of this.state.poi["Quizz" + this.state.idioma].respostes) {
            items.push(this._renderDraxElement(value))
        }

        return items
    }

    async clickElement(element) {
        if(element.tipus === 1 && this.state.valid1 !== 1) {
            await this.setState({
                valid1: (element.valid) ? 1 : 2,
                imageSrc1: api.api_url + this.state.recursos.find(e => e.tipus_recurs === element.image)["imatge_recurs" + this.state.idioma].url,
            })
        }
        else if(element.tipus === 2 && this.state.valid2 !== 1) {
            await this.setState({
                valid2: (element.valid) ? 1 : 2,
                imageSrc2: api.api_url + this.state.recursos.find(e => e.tipus_recurs === element.image)["imatge_recurs" + this.state.idioma].url,
            })
        }
        else if(element.tipus === 3 && this.state.valid3 !== 1) {
            await this.setState({
                valid3: (element.valid) ? 1 : 2,
                imageSrc3: api.api_url + this.state.recursos.find(e => e.tipus_recurs === element.image)["imatge_recurs" + this.state.idioma].url,
            })
        }
        this.setState({complete: (this.state.valid1 === 1 && this.state.valid2 === 1 && this.state.valid3 === 1)})
    }

    _renderDraxElement(element) {
        return(
            <TouchableOpacity
                key={element.id}
                style={[style.centeredContent, style.draggableBox, style.blue]}
                onPress={() => {
                    this.clickElement(element)
                }}
            >
                <Image style={{width: 82, height: 60, alignSelf: 'center'}}
                       source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === element.image)["imatge_recurs" + this.state.idioma].url }}/>
            </TouchableOpacity>
        )
    }

    _renderDragAndDrop() {
        return (
            <View style={style.container2}>
                <View>
                    <View style={{flex: 1, width: "100%", justifyContent: "center", alignItems: "center"}}>
                        <View style={[style.dropContainer, {height: Dimensions.get('screen').height * (13 / 15) * 0.20} ]}>
                            <View>
                                <Image style={{height: 85, width: 14.5}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'ruleta_part_1')['imatge_recurs'].url}}/>
                            </View>
                            <View style={[style.receiving,style.centeredContent,
                                style.receivingZone,
                                style.color1]}>
                                { (this.state.valid1 === 2) ?
                                    <Image style={style.crossImage} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'pop_up_close_icon')['imatge_recurs'].url}}/>
                                    : (this.state.valid1 === 1)  ?
                                        <Image style={style.crossImage} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'resposta_correcta_min')['imatge_recurs'].url}}/>
                                        : null }
                                <View style={style.combinedstyle}>
                                    {(this.state.imageSrc1 !== "") ?
                                        <Image style={{height: 61.86, width: 85, alignSelf: 'center', justifyContent: "center"}}
                                               source={{uri: this.state.imageSrc1 }}/>
                                        :
                                        <Text style={{fontSize: 15, fontWeight: 700, color: "white", textAlign: "center"}}>
                                            {this.state.recursos.find(e => e.tipus_recurs == 'text_ruleta_on')["text_recurs" + this.state.idioma]}
                                        </Text>
                                    }

                                </View>
                            </View>
                            <View>
                                <Image style={{height: 75, width: 9.5}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'ruleta_part_2')['imatge_recurs'].url}}/>
                            </View>
                            <View style={[style.receiving,style.centeredContent,
                                style.receivingZone,
                                style.color2]}>
                                { (this.state.valid2 === 2) ?
                                    <Image style={style.crossImage}
                                           source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'pop_up_close_icon')['imatge_recurs'].url}}/>
                                    : (this.state.valid2 === 1)  ? <Image style={style.crossImage}
                                                                          source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'resposta_correcta_min')['imatge_recurs'].url}}/>
                                        : null }
                                <View style={style.combinedstyle}>
                                    {(this.state.imageSrc2 !== "") ?
                                        <Image style={{height: 61.86, width: 85, alignSelf: 'center', justifyContent: "center"}}
                                               source={{uri: this.state.imageSrc2 }}/>
                                        :
                                        <Text style={{fontSize: 15, fontWeight: 700, color: "white", textAlign: "center"}}>
                                            {this.state.recursos.find(e => e.tipus_recurs == 'text_ruleta_epoca')["text_recurs" + this.state.idioma]}
                                        </Text>
                                    }

                                </View>
                            </View>
                            <View>
                                <Image style={{height: 75, width: 9.5}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'ruleta_part_2')['imatge_recurs'].url}}/>
                            </View>
                            <View style={[style.receiving,style.centeredContent,
                                style.receivingZone,
                                style.color3]}>
                                { (this.state.valid3 === 2) ?
                                    <Image style={style.crossImage}
                                           source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'pop_up_close_icon')['imatge_recurs'].url}}/>
                                    : (this.state.valid3 === 1)  ? <Image style={style.crossImage}
                                                                          source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'resposta_correcta_min')['imatge_recurs'].url}}/>
                                        : null }
                                <View style={style.combinedstyle}>
                                    {(this.state.imageSrc3 !== "") ?
                                        <Image style={{height: 61.86, width: 85, alignSelf: 'center', justifyContent: "center"}}
                                               source={{uri: this.state.imageSrc3 }}/>
                                        :
                                        <Text style={{fontSize: 15, fontWeight: 700, color: "white", textAlign: "center"}}>
                                            {this.state.recursos.find(e => e.tipus_recurs == 'text_ruleta_quant')["text_recurs" + this.state.idioma]}
                                        </Text>
                                    }

                                </View>
                            </View>
                            <View>
                                <Image style={{height: 100, width: 22.9, marginBottom: -18}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'ruleta_part_3')['imatge_recurs'].url}}/>
                            </View>


                        </View>
                        <View style={[style.palette,  {paddingRight: "5%", height: Dimensions.get('screen').height * (13 / 15) * 0.50}]}>
                                {this._renderDraxElements()}
                        </View>
                        <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.15, width: "80%", placeContent: "center"}}>
                            {this._renderContinue()}
                        </View>
                        <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.11}} />
                    </View>
                </View>

            </View>
        )
    }

    GoPremi() {
        if(this.state.load) {
            this.props.navigation.navigate('Premi', this.state);
        }
    }


    _renderContinue() {
        return (
            this.state.complete ?
                <TouchableOpacity style={style.containerContinue} onPress={() => {
                    this.GoPremi()
                }}>
                    <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs == 'text_continuar')["text_recurs" + this.state.idioma]}</Text>
                </TouchableOpacity>
            :
                <TouchableOpacity style={{
                    visibility: "hidden",
                    marginTop: 40,
                    paddingLeft: 15,
                    paddingRight: 15,
                    height: 50
                }}>
                    <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs == 'text_continuar')["text_recurs" + this.state.idioma]}</Text>
                </TouchableOpacity>
        )
    }

    render(){
        // console.log(this.state.showQuizz)
        return(
            <View style={{flex: 1}}>
                <View style={{flex: 15}}>
                        <ImageBackground source={{ uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '05_background_tipus2').imatge_recurs.url }} style={style.imageBackground}>
                            {!this.state.showQuizz ?
                                <TouchableOpacity style={style.TouchableOpacity} onPress={() => this._goQuizz()}>
                                    <View style={{flex: 1, marginTop: 30, justifyContent: 'center'}}>
                                        <View style={style.titolContainer}>
                                            <Text style={style.titol}>{(this.state.poi["Titol" + this.state.idioma]) ? this.state.poi["Titol" + this.state.idioma] : ""}</Text>
                                            <Text style={style.subtitol}>{(this.state.poi["Subtitol" + this.state.idioma]) ? this.state.poi["Subtitol" + this.state.idioma] : ""}</Text>
                                        </View>
                                    </View>
                                    <View style={{flex: 2, textAlign: "center", alignSelf: 'center'}}>
                                        <View style={style.descriptionContainer}>
                                            <Text style={style.descripcio}>{(this.state.poi["Descripcio" + this.state.idioma]) ? this.state.poi["Descripcio" + this.state.idioma] : ""}</Text>
                                        </View>
                                    </View>
                                    <View style={{flex: 4, marginTop: 20, marginBottom: 80}}>
                                        <Image style={{height: "90%", resizeMode: 'contain'}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'imatge_principal_quizz')['imatge_recurs' + this.state.idioma].url}} />
                                    </View>
                                </TouchableOpacity>
                                :
                                /*render quizz*/
                                this._renderDragAndDrop()
                            }


                        </ImageBackground>

                </View>
                <View style={{ flex: 2, bottom: 0, left: 0, right: 0, position: 'fixed', }}>
                    <Menu projecte={this.state}></Menu>
                </View>
            </View>

        )
    }

    /*render() {
        return (
            <View style={{flex: 1}}>
                <ImageBackground
                    source={{uri: api.api_url + this.state.projecte['FonsPantalla4' + this.state.idioma].url}}
                    style={style.imageBackground}>
                    <View style={{flex: 15}}>

                    </View>
                </ImageBackground>
            </View>
        )
    }*/
}
