import React from 'react';
import {View, Text, ImageBackground, Image, TouchableOpacity, ScrollView, Dimensions} from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import style from './style';
import api from '../config/api_urls';
import Menu from '../menu/menu';
import {Ionicons} from '@expo/vector-icons';
import AsyncStorage from '@react-native-community/async-storage';
import {postSatisfaccio, postStadistic} from '../../helpers/petitions'
import Modal from "modal-react-native-web";

//ordre 4
class Itineraris extends React.Component {


    constructor(props) {
        super(props);
        this.state = props.route.params;
        this.state['navigation'] = this.props.navigation;

        this.itineraris = (typeof this.state.projecte !== 'undefined' && typeof this.state.projecte.itineraris !== 'undefined') ? this.state.projecte.itineraris : [];
        this.state["selectedPage"] = {}
        //   console.log(this.state)

    }

    async componentDidMount() {
        let finItinerari = await AsyncStorage.getItem('finalitzacio_itinerari')
        if(finItinerari) {
            this.setState({"finalitzacioItinerari": finItinerari})
        }
    }

    loadItinerari(itinerari) {
        this.state.itinerariActual = itinerari;

        postStadistic({
            token: this.state.user_token,
            tipus_peticio: "Inici itinerari",
            peticio: "Inici itinerari " + itinerari.Nom
        })

        let retrievedObject = localStorage.getItem('itinerari' + this.state.itinerariActual.id);
        if (retrievedObject) {
            this.state['currentStateItinerari'] = JSON.parse(retrievedObject);
            this.props.navigation.navigate('Mapa', this.state)
        }
        else {
            this.props.navigation.navigate('InfoItinerari', this.state)
        }
    }

    _renderPopUp() {
        return (
            <Modal animationType="slide"
                   ariaHideApp={false}
                   transparent={true}>
                <View style={style.modalOutBackground}>
                    <View style={style.caixaPopUp}>
                        <ImageBackground
                            source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '05_background_tipus2')['imatge_recurs'].url}}
                            style={style.imageBackground}>
                            <TouchableOpacity style={style.crossBox} onPress={() => {
                                AsyncStorage.setItem('finalitzacio_itinerari', "");
                                this.setState({"finalitzacioItinerari": ""})
                            }}>
                                <Image style={style.crossImage}
                                       source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'pop_up_close_icon')['imatge_recurs'].url}}/>
                            </TouchableOpacity>
                            <Image style={{resizeMode: "contain", width: "100%", height: "20%"}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'satisfaccio_text').imatge_recurs.url}} />

                            <TouchableOpacity style={{width: "100%", height: "20%"}} onPress={() => {
                                postSatisfaccio({token: this.state.user_token, valoracio: "1", joc: this.state.finalitzacioItinerari})
                                AsyncStorage.setItem('finalitzacio_itinerari', "");
                                this.setState({"finalitzacioItinerari": ""})
                            }}>
                                <Image style={{resizeMode: "contain", width: "100%", height: "90%"}}
                                       source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'satisfaccio_1').imatge_recurs.url}} />
                            </TouchableOpacity>
                            <TouchableOpacity style={{width: "100%", height: "20%"}} onPress={() => {
                                postSatisfaccio({token: this.state.user_token, valoracio: "5", joc: this.state.finalitzacioItinerari})
                                AsyncStorage.setItem('finalitzacio_itinerari', "");
                                this.setState({"finalitzacioItinerari": ""})
                            }}>
                                <Image style={{resizeMode: "contain", width: "100%", height: "90%"}}
                                       source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'satisfaccio_2').imatge_recurs.url}} />
                            </TouchableOpacity>
                            <TouchableOpacity style={{width: "100%", height: "20%"}} onPress={() => {
                                postSatisfaccio({token: this.state.user_token, valoracio: "10", joc: this.state.finalitzacioItinerari})
                                AsyncStorage.setItem('finalitzacio_itinerari', "");
                                this.setState({"finalitzacioItinerari": ""})
                            }}>
                                <Image style={{resizeMode: "contain", width: "100%", height: "90%"}}
                                       source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'satisfaccio_3').imatge_recurs.url}} />
                            </TouchableOpacity>
                        </ImageBackground>

                        {(this.state.finalitzacioItinerari && this.state.finalitzacioItinerari !== "") ? this._renderPopUp() : ""}

                    </View>
                </View>
            </Modal>
        )
    }

    render() {
        const items = []
        if (this.itineraris.length > 0) {
            for (const [index, value] of this.itineraris.entries()) {
                let colorItinerari = "#a88f40"
                let colorTextItinerari = "white"
                let retrievedObject = localStorage.getItem('itinerari' + value.id);
                if (retrievedObject) {
                    retrievedObject = JSON.parse(retrievedObject)
                    if(retrievedObject.finalitzat) {
                        colorItinerari = "#715f4d"
                        colorTextItinerari = "#f7f4d3"
                    }
                    else if(retrievedObject.comencat) {
                        colorItinerari = "#f7f4d3"
                        colorTextItinerari = "#715f4d"
                    }
                }
                if (value.Tipus != "Tipus3") {
                    items.push(
                        <TouchableOpacity key={index} onPress={() => {
                            this.loadItinerari(value);
                        }}>
                            {/* //=> AQUI va la imatge  */}
                            <View style={[style.itinerariContainer, {backgroundColor: colorItinerari}]}>
                                <Text style={{fontSize: 14, fontWeight: 'bold', padding: 5}}>
                                    <Text style={[style.itinerariText, {color: colorTextItinerari}]}>{value['Nom'] ? value['Nom'].toUpperCase() : null}</Text>
                                </Text>
                            </View>
                        </TouchableOpacity>
                    )
                }
            }
        }


        return (
            <View style={{flex: 1}}>
                <View style={{flex: 15}}>
                    <ImageBackground
                        source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '05_background_tipus2').imatge_recurs.url }}
                        style={style.imageBackgroundHoritzontal}>
                            <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.45, justifyContent: 'center'}}>
                                <Image resizeMode="contain" style={style.imatgeRecurs} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'itineraris_imatge')['imatge_recurs' + this.state.idioma].url }}></Image>
                            </View>
                            <View style={[style.caixaOut, {height: Dimensions.get('screen').height * (13 / 15) * 0.45, maxHeight: Dimensions.get('screen').height * (13 / 15) * 0.45}]}>
                                <ScrollView style={style.caixa}>
                                    {items}
                                </ScrollView>
                            </View>
                        <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.10}} />
                    </ImageBackground>
                </View>
                <View style={{flex: 2, bottom: 0, left: 0, right: 0, position: 'fixed'}}>
                    <Menu projecte={this.state}></Menu>
                </View>
            </View>
        )
    }
}

export default Itineraris;