import React from 'react';
import {
    SafeAreaView,
    FlatList,
    View,
    Text,
    ImageBackground,
    TouchableOpacity,
    Image,
    Dimensions,
    ScrollView
} from 'react-native';
import style from './style';
import api from '../../config/api_urls'
import {gameId} from '../../Mapa/SampleData';
import Menu from "../../menu/menu";
import BounceLoader from "react-spinners/BounceLoader";
import ReactMarkdown from 'react-markdown'

export default class CheckIn extends React.Component {

    constructor(props) {
        super(props);
        this.state = props.route.params;
        this.state["loading"] = false;
        this.state["checkIn"] = false;
        this.state["showContinue"] = false;
    }

    _checkIn() {
        this.setState({loading: true})
        setTimeout(function() { //Start the timer
            this.setState({ checkIn: true });
            this.setState({loading: false})
        }.bind(this), 2000)
    }


    goPremi() {
        this.props.navigation.navigate('Premi', this.state);
    }

    _renderCheckIn() {
        return (
            <ImageBackground source={{ uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '05_background_tipus2').imatge_recurs.url }} style={style.imageBackground}>
                <View style={{flex: 21, justifyContent: 'center', alignItems: 'center', width: "100%"}}>
                    <View style={style.questionBackground}>
                        <View style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={style.question}>{this.state.poi["Titol" + this.state.idioma]}</Text>
                        </View>
                    </View>
                </View>
                <View style={{flex: 44, justifyContent: 'center', alignItems: 'center', width: "100%"}}>
                    <View style={{flex: 1, width:"80%", alignItems: 'center', justifyContent: 'center'}}>
                        {this._paintImage()}
                    </View>
                    {(this.state.poi.Tipus === "Info") ?
                        <ScrollView style={{flex: 1, width: "80%", fontFamily: 'OpenSans', fontSize: "2.3vh"}}>
                            <View style={{width: "100%", justifyContent: 'center', fontFamily: 'OpenSans', fontSize: "2.3vh"}}>
                                <ReactMarkdown style={style.descripcio}>{this.state.poi["descripcioDetallada" + this.state.idioma]}</ReactMarkdown>
                            </View>
                        </ScrollView>
                        : null
                    }
                </View>
                <View style={{flex: 25, justifyContent: 'center'}}>
                    {this._paintButton()}
                </View>

                <View style={{flex: 10}} />

            </ImageBackground>
        )
    }

    _paintImage() {
        if(this.state.checkIn) {
            return (
                    <Image
                        resizeMode="contain"
                        style={style.checkinImage}
                        source={{uri:  api.api_url + this.state.poi.Checkin2.url}}
                    />
            )
        }
        else {
            return(
                    <Image
                        resizeMode="contain"
                        style={style.checkinImage}
                        source={{uri:  api.api_url + this.state.poi.Checkin1.url}}
                    />
            )
        }
    }

    _paintButton() {
        if(this.state.loading) {
            return(
                <BounceLoader size={50} color={"#715f4d"} loading={this.state.loading} speedMultiplier={1.5} />
            )
        }
        else if(!this.state.loading && !this.state.checkIn){
            return(
                <TouchableOpacity style={{height: 70, width: 70, backgroundColor: "#a88f40", alignItems: 'center', justifyContent: 'center'}} onPress={() => {
                    this._checkIn()
                }}>
                    <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs === 'check_in')['text_recurs' + this.state.idioma]}</Text>
                </TouchableOpacity>
            )
        }
        else if(!this.state.loading && this.state.checkIn){
            return(
                <TouchableOpacity style={{paddingLeft: 15, paddingRight: 15, height: 50, backgroundColor: "#a88f40", alignItems: 'center', justifyContent: 'center'}} onPress={() => {
                    this.goPremi()
                }}>
                    <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs === 'text_continuar')['text_recurs' + this.state.idioma]}</Text>
                </TouchableOpacity>
            )
        }

    }

    render(){
        return(
            <View style={style.container} >
                <View style={{ flex: 15}}>
                        {this._renderCheckIn()}
                </View>
                <View style={{ flex: 2, bottom: 0, left: 0, right: 0, position: 'fixed', }}>
                    <Menu projecte={this.state}></Menu>
                </View>
            </View>

        )
    }

    /*render() {
        return (
            <View style={{flex: 1}}>
                <ImageBackground
                    source={{uri: api.api_url + this.state.projecte['FonsPantalla4' + this.state.idioma].url}}
                    style={style.imageBackground}>
                    <View style={{flex: 15}}>

                    </View>
                </ImageBackground>
            </View>
        )
    }*/
}
