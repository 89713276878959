import React from 'react';
import {
    SafeAreaView,
    FlatList,
    View,
    Text,
    ImageBackground,
    TouchableOpacity,
    Image,
    Dimensions,
    ScrollView
} from 'react-native';
import style from './style';
import api from '../../config/api_urls'
import Menu from "../../menu/menu";
import ReactMarkdown from 'react-markdown'

export default class Moviment extends React.Component {

    constructor(props) {
        super(props);
        this.state = props.route.params;
    }



    goPremi() {
        this.props.navigation.navigate('Premi', this.state);
    }


    _paintButton() {
            return(
                <TouchableOpacity style={style.boxContinuar} onPress={() => {
                    this.goPremi()
                }}>
                    <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs === 'text_continuar')['text_recurs' + this.state.idioma]}</Text>
                </TouchableOpacity>
            )
    }
    

    render(){
        return(
            <View style={style.container} >
                <View style={{ flex: 15}}>
                <ImageBackground source={{ uri: api.api_url + this.state.itinerariActual['fons_joc'].url}} style={style.imageBackground}>
                        <View style={{flex: 30, justifyContent: 'center', alignItems: 'center',resizeMode:'cover',width: "80%"}}>
                            <ImageBackground source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == 'imatge_moviment')['imatge_recurs'].url}}
                               style={{width:'100%',height:'100%',alignItems:'center',justifyContent:'center'}} resizeMode='contain' >
                                 <View style={{width: "80%", justifyContent: 'center', fontFamily: 'OpenSans', fontSize: "2vh",alignItems:'center',color:'white',fontWeight: 'bold',alignSelf:'center'}}>
                                    <ReactMarkdown style={style.descripcio}>{this.state.jocActiu["Nom" + this.state.idioma]}</ReactMarkdown>
                                </View>
                            </ImageBackground>
                        </View>
                        <View style={{flex: 54, width: "80%",height:'80%', fontFamily: 'OpenSans', fontSize: 16,textAlign: "center",fontWeight: 500,paddingTop:20,justifyContent:'center'}}>
                            <ScrollView style={{height:'100%', fontFamily: 'OpenSans', fontSize: 16,textAlign: "center",fontWeight: 500,paddingTop:20,}}>
                                <View style={{width: "100%", justifyContent: 'center', fontFamily: 'OpenSans', fontSize: "2.2vh",}}>
                                    <ReactMarkdown style={style.descripcio}>{this.state.jocActiu["descripcioDetallada" + this.state.idioma]}</ReactMarkdown>
                                </View>
                            </ScrollView>
                        </View>
                        <View style={{flex: 15, justifyContent: 'center',alignItems:'center'}}>
                            {this._paintButton()}
                        </View>
                        <View style={{flex: 10}} />
                    </ImageBackground>
                </View>
                <View style={{ flex: 2, bottom: 0, left: 0, right: 0, position: 'fixed', }}>
                    
                        <Menu projecte={this.state} ></Menu>
                </View>
            </View>
        )
        
    } 
}
